@mixin darkThemeContent() {
    & * {
        color: white;
    }
    
    & p span {
        color: white !important;
    }
    
    & .container-txt * {
        color: white !important;
    }
    
    & #navbar-container {
        border-bottom: 1px solid var(--color-yellow) !important;
    }
    
    & .container-txt a, 
    & .container-txt a * {
        color: var(--color-yellow) !important;
        text-decoration: underline !important;
    }
    
    & h2,
    & h2 * {
        color: var(--color-yellow) !important;
    }

    & .blog-header-container * {
        color: white !important;
        opacity: 1 !important;
    }
    
    & #mktzap-demonstration img,
    & #banner-container .logo-variant img,
    & .blog-header-container img,
    & .logo-system img {    
        filter: saturate(1) grayscale(1) brightness(10);    
    }
    
    & .footer-bottom,
    & header .navbar-brand img,
    & #jobs-mid-section-card img,
    & #brand-logo .content img {    
        filter: saturate(1) grayscale(1) invert(1) brightness(10);    
    }

    & #donate-institute img {
        filter: saturate(1) grayscale(1) invert(1) brightness(3);
    }
    
    & #logo-figure * {
       filter: saturate(1)invert(1) brightness(200%); 
       opacity: 1;
    }
    
    & #jobs-mid-section-card h2,
    & #jobs-mid-section-card span {
        color: white !important;
        opacity: 1 !important;
    }
    
    & .icon *,
    & .icon *:before {
        fill: var(--color-yellow) !important;
        color: var(--color-yellow) !important;    
    }
    
    & .btn-primary,
    & .btn-primary:hover,
    & .btn-primary:active,
    & .btn-primary:focus,
    & .btn-primary *,
    & .btn-secondary,
    & .btn-secondary:hover,
    & .btn-secondary:active,
    & .btn-secondary:focus,
    & .btn-secondary *,
    & .btn-tertiary,
    & .btn-tertiary:hover,
    & .btn-tertiary:active,
    & .btn-tertiary:focus,
    & .btn-tertiary * {    
        background: var(--color-yellow-off) !important;
        color: black !important;    
    }
    
    & .community-numbers h2 {
        opacity: 1;
    }
    
    & #features {
        background: black;
    }
    
    & #features h3,
    & #features h3 *,
    & #features h4 {
        color: var(--color-yellow);
    }
    
    & .number-info-row * {
        color: white;
    }
    
    
    & .all-content-container  * {    
        color: var(--color-yellow-off) !important;
        text-decoration: underline;    
    }

    & .all-content-container:hover a {
        color: var(--color-yellow-off) !important;
    }
    
    & .post-link,
    & .post-link * {
        color: var(--color-yellow) !important;
        text-decoration: underline;
    }
    
    & #navbar-container .navbar-toggler {
        background: var(--color-yellow);
    }
    
    
    & footer * {
        color: white !important;
        opacity: 1;
    }
    
    & footer a {
        color: var(--color-yellow-off) !important;
        text-decoration: underline;
    }
    
    & .paper-container-rounder .container-scheduling {
        background: none !important;
    }
    
    & .paper-container-rounder:before {
        background: none !important;
        box-shadow: none !important;
        display: none !important;
    }
    
    & #banner-container {
        background: none;
        box-shadow: unset;
    }

    & #material-header {
        background-image: none;
        box-shadow: unset;
    }

    & #blog-page #materials .container::after {
        background-image: unset;
    }

    & .post > .content .content-blog h3 {
        color: var(--color-yellow);
    }

    & .post > .content .content-blog a,
    & .post > .content .content-blog a strong {
        color: var(--color-yellow);
        text-decoration: underline;
    }

    & .blog-brand-container span {
        opacity: 1;
    }

    & #features .feature-detail-top,
    & #features .feature-detail {
        background-image: unset;
    }

    & #chat-simulation .chat-message-wrapper .chat-message-robot .chat-message p {
        color: #050505;
    }

    & .label.tertiary {
        background-color: var(--color-yellow);
        color: #050505;
    }

    & .loading-title,
    & .loading-title *,
    & .loading-subtitle,
    & .loading-subtitle * {
        color: transparent !important;
    }

    & #navbar.navbar-blog .dropdown-menu a.dropdown-item.active, 
    & #navbar.navbar-blog .dropdown-menu a.dropdown-item:active, 
    & #navbar.navbar-blog .dropdown-menu a.dropdown-item:hover, 
    & #navbar.navbar-blog .dropdown-menu a.dropdown-item:focus, 
    & #navbar.navbar-blog .nav-link.active, 
    & #navbar.navbar-blog .nav-link:active, 
    & #navbar.navbar-blog .nav-link:hover, 
    & #navbar.navbar-blog .nav-link:focus {
        color: var(--color-yellow) !important;
    }

    & input,
    & select {
        background-color: #050505;
    }

    & input::placeholder,
    & select::placeholder,
    & select:invalid {
        opacity: 1;
        color: var(--color-white);
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover, 
    & input:-webkit-autofill:focus,
    & textarea:-webkit-autofill,
    & textarea:-webkit-autofill:hover,
    & textarea:-webkit-autofill:focus,
    & select:-webkit-autofill,
    & select:-webkit-autofill:hover,
    & select:-webkit-autofill:focus {
        -webkit-text-fill-color: white !important;
        -webkit-box-shadow: 0 0 0px 1000px rgb(54, 54, 54) inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
    }

    & .play-button.btn:hover .play-button-icon,
    & .play-button.btn:focus .play-button-icon,
    & .play-button.btn:active .play-button-icon {        
        box-shadow: unset;
    }

    & #header-admin {
        background: #050505;
    }

    & #header-admin h2,
    & #header-admin .header-admin-sign-out > svg,
    & #header-admin .header-admin-sign-out > button {
        color: white !important;
        opacity: 1;
    }

    & #breadcrumbs ol li a:not(.active) .title {
        color: yellow !important;
    }

    & .select__option--is-focused,
    & .select__option--is-selected,
    & .select__multi-value__label,
    & .select__multi-value__remove svg path {
        color: #050505;
    }
}