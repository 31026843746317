.navbar-collapse.show,
.navbar-collapse.collapsing {
    display: flex;
    width: 100VW;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    align-items: flex-start;
    transition: all 0.2s ease-in-out;
}

.navbar-collapse.collapsing {
    min-height: 1px;
    height: 1px !important;
    display: none;
}

.navbar-collapse.show {
    min-height: calc(100VH - 68px);
    height: calc(100VH - 68px);
}

.navbar-collapse.show .ml-auto.navbar-nav,
.navbar-collapse.collapsing .ml-auto.navbar-nav {
    margin-top: 3rem;
    margin-right: auto;
}

#navbar .navbar-collapse.show .nav-item,
#navbar .navbar-collapse.collapsing .nav-item {
    margin-right: 0;
    font-size: 2rem;
    align-self: center;
    margin-bottom: 2rem;
}

#navbar .navbar-collapse.show .nav-item a,
#navbar .navbar-collapse.collapsing .nav-item a {
    text-align: center;
}

#navbar-container {
    background-color: var(--color-navbar-menu-background);
}

#navbar-container.fixed {
    background-color: var(--color-navbar-menu-background-mobile);
    position: fixed;
    z-index: 16000010;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

#navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7' ><path opacity='.6' fill='%23666E6E' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/></svg>") !important;
    background-size: 150%;
}

#root .App #app-wrapper > header:not(#header-admin) {
    min-height: 83px;
}

@media (min-width: 992px) {
    #root .App #app-wrapper > header {
        min-height: 0;
    }

    .navbar-collapse.show {
        min-height: initial;
        height: initial;
        width: initial;
    }

    #navbar-container.fixed {
        background-color: inherit;
        position: fixed;
        padding: 1.4rem 0;
        top: 0;
        z-index: 10;
        transition: background-color 0.3s ease-in-out;
        overflow: inherit;
    }

    #navbar-container.fixed.sticky {
        background-color: white;
        z-index: 20;
        box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
        padding-bottom: 0.2rem;
        padding-top: 0;
    }

    .navbar-collapse.show .ml-auto.navbar-nav {
        margin-top: 0;
        margin-right: 0;
    }

    #navbar .navbar-collapse.show .nav-item,
    #navbar .navbar-collapse.collapsing .nav-item {
        align-self: center;
        margin: 0 1rem;
        font-size: 1.6rem;
    }

    #navbar .navbar-collapse.show .nav-item a,
    #navbar .navbar-collapse.collapsing .nav-item a {
        text-align: left;
    }
}