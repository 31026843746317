:root {
    font-size: 60%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    min-height: 100vh;
}

#root, .App {
    display: flex;
    /* align-items: center;
    justify-content: center; */
}

.overflow-hidden {
    overflow: hidden;
}

img {
    width: 100%;
    align-self: flex-start;
    /* hack to safari height */
}

a,
a:hover,
a:active,
a:visited {
    color: inherit;
    text-decoration: none;
}

#root button {
    outline: none;
}

.hidden {
    display: none;
}

.App,
#app-wrapper {
    width: 100%;
}

.transition-fade-enter {
    opacity: 0;
}

.transition-fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition-fade-exit {
    opacity: 0;
}

.transition-fade-exit-active {
    opacity: 0;
}

.background-template {
    background-repeat: no-repeat;
    background-size: 174rem;
    width: 100%;
    background-position: 0 0;
}

button:focus,
input:focus {
    outline: none;
}

.effect-hover-shadow-up {
    transition: all 0.5s;
}

div.content-video {
    margin: 0 0 1rem;
}

div.anchor {
    position: absolute;
}

.inherit {
    all: inherit;
}
.gray-bg{
  background-image: url('https://d3q3w18r1z0nt6.cloudfront.net/images/pages/teste-gratis/background-onboarding.png');
  background-repeat: no-repeat;
  background-size: 174rem;
  width: 100%;
  background-position: -300px 0;
}

.default-bg {    
  background-image: url('../../assets/images/pages/login/background.png');
  background-size: 174rem;
  background-repeat: no-repeat;
  width: 100%;
  background-position: -200px 0;
}

@media (min-width: 568px) {

    .default-bg {
      background-position: right 100% top;
    }
  }
  
  
  @media (min-width: 992px) {

    .default-bg {
      background-position: right calc(100% - 100px) top;
    }
  }
  
  @media (min-width: 1500px) {

    .default-bg {
      background-position: right -255px top;
    }
  }
  
  @media (min-width: 1740px){ 

    .default-bg {
      background-position: right -155px top;
    }
  }
  
  @media (min-width: 1920px) {

    .default-bg {
      background-position: calc(100% + 350px) 0;
      background-size: initial;
    }
  }
  
  @media (min-width: 2300px) {

    .default-bg {
        background-position: 125% 0;
    }
  }
  
  @media (min-width: 2500px) {

    .default-bg {
      background-position: 100% 0;
    }
  }
  
  @media (min-width: 3000px) {

    .default-bg {
        background-position: 70% 0;
    }
  }