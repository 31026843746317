#header-section #navbar-content {
    max-width: 1350px;
}

.navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navbar-wrapper nav {
    width: 100%;
}

#navbar .navbar-brand img {
    max-width: 19rem;
}

#navbar .navbar-toggler {
    border: 0;
    font-size: 2rem;
}

#navbar .nav-item .dropdown-toggle::after {
    display: none;
}

#navbar .nav-item .dropdown-toggle svg {
    margin-left: 0.5rem;
    opacity: 0.3;
}

#navbar .nav-item {
    align-self: flex-end;
    font-weight: bold;
    margin-right: 0.5rem;
}

#navbar .nav-item a {
    text-align: right;
}

#navbar .nav-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#navbar .dropdown-menu {
    margin: 0px;
    font-size: 1.6rem;
    border: 0;
    background-color: transparent;
}

#navbar.navbar-light .navbar-nav .nav-link {
    color: var(--color-navbar-menu-item);
}

#navbar.navbar-light .navbar-nav .nav-link:hover,
#navbar.navbar-light .navbar-nav .nav-link:active,
#navbar.navbar-light .navbar-nav .nav-link:focus,
#navbar.navbar-light .navbar-nav .nav-link.active {
    color: var(--color-navbar-menu-item-hover);
}

#navbar .dropdown-menu a.dropdown-item {
    padding: 0.5rem 0.25rem;
    background-color: inherit;
    color: var(--color-navbar-dropdown-menu-item);
    transition: opacity 0.1s ease-in-out;
}

#navbar .dropdown-menu a.dropdown-item:hover,
#navbar .dropdown-menu a.dropdown-item:focus,
#navbar .dropdown-menu a.dropdown-item:active {
    color: var(--color-navbar-dropdown-menu-item-hover);
}

#navbar-container #navbar .dropdown-menu a.dropdown-item {
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 0.8rem;
}

#navbar-container #navbar .dropdown-menu a.dropdown-item:last-of-type {
    margin-bottom: 0;
}

#navbar .dropdown-menu a.dropdown-item:hover,
#navbar .dropdown-menu a.dropdown-item:focus {
    background-color: transparent;
}

#navbar .dropdown-menu a.dropdown-item.active {
    color: var(--color-navbar-dropdown-menu-item-active);
}

#navbar-container .navbar-toggler {
    background-color: var(--color-navbar-toggler-background);
}

@media (min-width: 768px) {
    #navbar-container.sticky {
        box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
    }
}

@media (min-width: 992px) {
    #navbar-container .container {
        max-width: 1125px;
    }

    #navbar .nav-item {
        align-self: center;
        margin: 0 1rem;
    }

    #navbar .nav-item a {
        text-align: left;
    }

    #navbar .nav-item>button {
        font-weight: bold;
    }

    #navbar .nav-actions {
        align-self: center;
        flex-direction: row;
        margin-left: 3rem;
    }

    #navbar .nav-actions .nav-item {
        margin: 0;
        align-self: stretch;
    }

    #navbar .nav-actions button.btn.btn-secondary {
        font-size: 1.3rem;
    }

    #navbar .dropdown-menu {
        padding: 1rem;
        z-index: 1;
        transition: background-color 0.4s ease-in-out;
        border-radius: 1rem;
    }

    #navbar-container #navbar .dropdown-menu {
        background-color: var(--color-navbar-dropdown-menu);
        box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
        padding: 2.4rem;
        margin-top: 1.4rem !important;
    }

    #navbar-container #navbar .navbar-nav>div:last-of-type .dropdown-menu {
        right: 0;
        left: auto;
    }
}