@import 'dark-theme';

@mixin theme() {
    & {        
        background-color: var(--color-background);
    }
    & ,
    & input,
    & button,
    & textarea {
        font: 500 1.6rem 'Nunito', sans-serif;
        color: var(--color-text-base);        
    }

    & h1, & .h1 {
        font-size: var(--h1-font-size);
    }

    & .suspense {
        min-height: 200vh;
        background-color: var(--color-background);
    }
}

@mixin darkTheme(){
    & .dark-theme {
        @content;
        @include theme;
        @include darkThemeContent;
    }
}
@mixin lightTheme(){
    & .light-theme {
        @content;
        @include theme;
    }
}

body {
    @include darkTheme(){
        --color-background: #050505;
        --color-background-off: #0F0E0D;
        --color-text-base: white;
        --color-text-base-hover: #f8f8f8;
        --color-separator: #a59a8f1a;
        --color-button-shadow: #1d1f1cde;
        --color-button-primary: #ffffff;
        --color-button-primary-hover: #f8f8f8;
        --color-button-primary-text: #5a6570;
        --color-button-secondary: #0C643D;
        --color-button-secondary-hover: #0D4A34;
        --color-button-secondary-text: #ffffff;
        --color-button-tertiary: #9A0EA4;
        --color-button-tertiary-hover: #7b1183;
        --color-button-tertiary-text: #f1f1f1;
        --color-purple: #BD7BFE;
        --color-purple-hover: #9831fe;
        --color-green: #0C643D;
        --color-white: #ffffff;
        --color-white-off: #f0f0f06e;
        --color-pink: #ED7FF5;
        --color-feature-links: #f2f2f2;
        --color-yellow: yellow;
        --color-yellow-off: #eaff00;
        --color-hover-yellow: #ffcd2d;
        --color-pink-text:#ED7FF5;
        --color-title-awards: #8E31F4;
        --color-border-input: #E6E9ED;
        --color-border-input-error: #fa5757;
        --color-placeholder-input: #5a65708a;
        --color-success: #008b4f;
        --h1-font-size: 4rem;
        --text-highlight-color: #9731fe4d;
        --color-navbar-menu-background: rgba(0, 0, 0, 0.8);
        --color-navbar-menu-background-mobile: #000;
        --color-navbar-menu-background-sticky: #0F0E0D;
        --color-navbar-toggler-background: #353433;
        --color-navbar-dropdown-menu: #0F0E0D;
        --color-navbar-dropdown-menu-item:white;
        --color-navbar-dropdown-menu-item-active: yellow;
        --color-navbar-dropdown-menu-item-hover: yellow;
        --color-navbar-menu-item: white;
        --color-navbar-menu-item-hover: yellow;
        --color-footer: #000;        
        --color-card-segment-card: #a59a8f1a;
        --color-container-blog: #0F0E0D;
        --color-paper-container-rounder: #0F0E0D;
        --color-mid-section-card: #0F0E0D;
        --color-play-button-background: #353433;
        --color-play-button-background-hover: #494847;
        --color-carousel-button-background: #353433;
        --color-title-hr: #A59A8F;
        --color-steppers-icon-complete-background: unset;
        --color-steppers-icon-waiting-background: #DCDCDC;
        --outline-color: --color-text-base;
    }
    
    @include lightTheme() {
        --color-background: #fafafa;
        --color-background-off: #F0F1F2;
        --color-text-base: #5a6570;
        --color-text-base-hover: #5a6570de;
        --color-separator: rgba(90, 101, 112, 0.1);
        --color-button-shadow: rgba(38, 7, 66, 0.1);
        --color-button-primary: #ffffff;
        --color-button-primary-hover: #f8f8f8;
        --color-button-primary-text: #5a6570;
        --color-button-secondary: #00d473;
        --color-button-secondary-hover: #00c166;
        --color-button-secondary-text: #ffffff;
        --color-button-tertiary: #f057fa;
        --color-button-tertiary-hover: #e047ea;
        --color-button-tertiary-text: #f1f1f1;
        --color-purple: #9831fe;
        --color-purple-hover: #7725c9;
        --color-green: #00d473;
        --color-white: #ffffff;
        --color-white-off: #f0f0f06e;
        --color-pink: #f057fa;
        --color-feature-links: #f2f2f2;
        --color-hover-yellow: #ffcd2d;
        --color-pink-text:#E657F0;
        --color-title-awards: #8E31F4;
        --color-border-input: #E6E9ED;
        --color-border-input-error: #fa5757;
        --color-placeholder-input: #5a65708a;
        --color-success: #008b4f;
        --h1-font-size: 4rem;
        --text-highlight-color: #9731fe4d;
        --color-navbar-menu-background: unset;
        --color-navbar-menu-background-mobile: #fafafa;
        --color-navbar-menu-background-sticky: #FFF;
        --color-navbar-toggler-background: unset;
        --color-navbar-dropdown-menu: #FFF;
        --color-navbar-dropdown-menu-item: rgba(33, 37, 41, 0.5);
        --color-navbar-dropdown-menu-item-active: rgba(0, 0, 0, 0.9);
        --color-navbar-dropdown-menu-item-hover: rgba(33, 37, 41, 0.7);
        --color-navbar-menu-item: rgba(0, 0, 0, 0.5);
        --color-navbar-menu-item-hover: rgb(0, 0, 0, 0.7);
        --color-footer: #FFF; 
        --color-card-segment-card: #FFF;
        --color-container-blog: #FFF;
        --color-paper-container-rounder: #FFF;
        --color-mid-section-card: #FFF;
        --color-play-button-background: #FFF;
        --color-play-button-background-hover: #f5f2f2;
        --color-carousel-button-background: #FFF;
        --color-title-hr: rgba(0, 0, 0, 0.1);
        --color-steppers-icon-complete-background: #f8f8f8;
        --color-steppers-icon-waiting-background: #5a657029;
        --outline-color: --color-text-base;
    }
}